<template>
  <app-dialog class="logout-dialog">
    <div class="head">ログアウトしてください</div>
    <div class="sub">
      <app-icon
        name="checkCircleRed"
        class="icon"
        width="20"
        height="20"
      />
      以下をご確認ください
    </div>
    <div
      v-if="reason === 'newPasswordNeeded'"
      class="main"
    >
      セキュリティオプションがONになりました。<br />一度ログアウトし、再度ログインをしてください。
    </div>
    <div
      v-else-if="reason === 'currentIpIsInvalid'"
      class="main"
    >
      IPが制限されているためログアウトします。
    </div>
    <div class="foot">
      <app-button
        variant="primary"
        class="button"
        @click="logout"
        >ログアウトする</app-button
      >
    </div>
  </app-dialog>
</template>

<script setup lang="ts">
const { $client } = useNuxtApp()
const $router = useRouter()

interface Props {
  reason: string
}
defineProps<Props>()

const logout = async (): Promise<void> => {
  await $client.v3.logout.$delete()
  await $router.push('/auth/login')
}
</script>

<style lang="scss" scoped>
.logout-dialog {
  > .dialog {
    > .head {
      width: 691px;
      box-sizing: border-box;
      padding: 110px 40px 0;
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      text-align: center;
    }
    > .sub {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 41px 0 0;
      font-size: $font-size-18;
      color: $error-color;
      text-align: center;
      > .icon {
        padding-right: 13px;
      }
    }
    > .main {
      padding: 28px 24px;
      margin: 20px 40px 37px;
      font-size: $font-size-18;
      line-height: $lh-31;
      color: $error-color;
      background-color: $error-bg-color;
      border-radius: 10px;
    }
    > .foot {
      padding: 0 40px 37px;
      text-align: center;
      > .button {
        width: 244px;
      }
    }
  }
}
</style>
