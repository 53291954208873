<template>
  <header class="app-header">
    <div
      class="breadcrumb"
      :class="{ '-collapsed': !isCollapsed }"
    >
      <app-breadcrumb />
    </div>
    <div
      ref="elRoot"
      class="menu"
      @click="isDropDownVisible = !isDropDownVisible"
    >
      <app-avatar
        class="user"
        :image-uri="userData?.image_uri"
      />
      <div
        class="caret"
        :class="{ '-open': isDropDownVisible }"
      ></div>
      <transition name="dropdown">
        <div
          v-show="isDropDownVisible"
          class="dropdown"
          @click.stop
        >
          <label class="user">
            <input
              type="file"
              hidden
              :accept="imageFileTypes.join(',')"
              @change="uploadUserImage($event)"
            />
            <app-avatar
              class="avatar"
              :image-uri="userData?.image_uri"
            />
            <app-icon
              name="photoCamera"
              class="icon"
              width="22"
              height="18"
            />
          </label>

          <div class="info">
            <span class="name">{{ userData?.name }}</span>
            <div class="dept">{{ userData?.department }}</div>
            <div
              class="logout"
              @click="logout"
            >
              ログアウト
            </div>
          </div>
        </div>
      </transition>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { User } from '~/types/user'
import useFlashMessage from '~/composables/useFlashMessages'
import { useI18n } from 'vue-i18n'

interface Props {
  user: User
  isCollapsed: boolean
}

const { showFlashMessage } = useFlashMessage()
const { t } = useI18n()
const { $client } = useNuxtApp()
const props = defineProps<Props>()
const router = useRouter()
const isDropDownVisible = ref(false)
const userData = ref(props.user)
const imageFileTypes = ref(['image/png', 'image/jpg', 'image/jpeg'])
const $store = useStore()

/* eslint-disable  @typescript-eslint/no-explicit-any */
const logout = async (): Promise<void> => {
  try {
    $store.setDepartments(null)
    await $client.v3.logout.$delete()
    router.push('/auth/login')
  } catch (error: any) {
    showFlashMessage(
      'fail',
      error.response?.data?.message || t('errors.default'),
    )
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
const uploadUserImage = async (event: Event): Promise<void> => {
  if (userData.value) {
    const file = (event.target as HTMLInputElement).files![0]
    if (!file) {
      return
    }
    const payload = {
      image: file,
      _method: 'PUT',
    }
    try {
      const res = await $client.v4.common.users
        ._id(userData.value.id)
        .image.$post({
          body: payload,
        })
      userData.value.image_uri = res.result.image_uri
      showFlashMessage('success', 'アイコンを更新しました')
      isDropDownVisible.value = false
    } catch (err: any) {
      showFlashMessage(
        'fail',
        err.response?.data?.message || 'アイコンの更新に失敗しました',
      )
    }
  }
}

const elRoot = ref()
const onBlurHandler = (event: Event): void => {
  if (elRoot.value && elRoot.value.contains(event.target)) {
    return
  }
  isDropDownVisible.value = false
}

onMounted(() => {
  window.addEventListener('click', onBlurHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('click', onBlurHandler)
})
</script>

<style lang="scss" scoped>
.app-header {
  display: grid;
  grid-template: 'Breadcrumb Menu';
  justify-content: space-between;
  height: 50px;
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
  background-color: $white-color;
  box-shadow: $box-shadow-9;

  .breadcrumb {
    grid-area: Breadcrumb;
    padding-left: 54px;

    &.-collapsed {
      padding-left: 259px;
    }
  }

  > .menu {
    grid-area: Menu;
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    gap: 16px;

    > .user {
      width: 32px;
      height: 32px;
      margin-top: calc((50px - 32px) / 2);
      overflow: hidden;
      cursor: pointer;
      border-radius: 27%;
    }

    > .caret {
      width: 7px;
      height: 7px;
      margin-left: 7px;
      border-right: solid 2px $black-color;
      border-bottom: solid 2px $black-color;
      transform: rotate(45deg);

      &.-open {
        border-color: $primary-color;
      }
    }

    > .dropdown {
      position: absolute;
      top: 50px;
      right: 0;
      display: flex;
      max-height: 95px;
      overflow-y: hidden;
      background-color: $white-color;
      border-radius: 10px;
      box-shadow: $box-shadow-9;

      &.dropdown-enter-active,
      &.dropdown-leave-active {
        transition: max-height 0.3s;
      }

      &.dropdown-enter,
      &.dropdown-leave-active {
        max-height: 0;
      }

      > .user {
        width: 43px;
        height: 43px;
        margin: 15px 10px 18px 20px;
        position: relative;

        > .avatar {
          width: 100%;
          height: 100%;
        }

        > .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
        }
      }

      > .info {
        min-width: 120px;
        margin: 15px 20px 18px 0;

        > .name {
          margin-bottom: 5px;
          font-weight: $font-weight-bold;
        }

        > .dept {
          margin-bottom: 13px;
          font-size: $font-size-small;
          color: $dark-blue-color;
        }

        > .logout {
          font-size: $font-size-small;
          color: $primary-color;
          cursor: pointer;

          &:hover {
            color: $skyblue-4;
          }
        }
      }
    }
  }
}
</style>
