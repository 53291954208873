<template>
  <div
    :class="{ '-isCollapsed': collapsed }"
    class="side-menu-item"
  >
    <div
      class="title-item"
      @click="open"
    >
      <div class="text">
        <app-icon
          :name="icon"
          width="20"
          height="20"
        />
        <span class="title">{{ title }}</span>
      </div>
      <div
        v-show="!collapsed"
        class="arrow-icon"
        :class="{ '-open': visible }"
      ></div>
    </div>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-if="!collapsed && visible"
        class="child-items"
      >
        <div
          v-for="(child, childIndex) in childs"
          v-show="visible && canAccessPage(child)"
          :key="childIndex"
          :class="{
            '-matchedRoute': matchedRouteOrAlias(child),
          }"
          class="child-item"
        >
          <nuxt-link
            :to="child.href"
            class="child-link"
          >
            <div class="title">
              <span>{{ child.title }}</span>
              <app-badge
                v-if="child.badge && child.badge.text && child.badge.text > 0"
                :num="child.badge.text"
              />
            </div>
            <div
              class="arrow-icon"
              :class="{
                '-white': matchedRouteOrAlias(child),
              }"
            ></div>
          </nuxt-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import type { ChildPage, PageInfo } from '~/config/pagesInfo'
import type { RoleKey } from '~/types/roleKey'
interface Props {
  title: string
  icon: string
  childs: ChildPage[] | undefined
  visible: boolean
  collapsed: boolean
}

defineProps<Props>()
const emit = defineEmits(['open'])

const open = (): void => {
  emit('open')
}

const $store = useStore()
const $route = useRoute()

const canAccessPage = (page: PageInfo): boolean => {
  const userRole = $store.getUser?.roles ? $store.getUser?.roles[0].value : null
  if (!userRole) {
    return false
  }

  if (page.roles?.includes(userRole as RoleKey)) {
    return true
  }

  if (page.child) {
    return page.child.some((subPage: ChildPage) =>
      subPage.roles?.includes(userRole as RoleKey),
    )
  }
  return false
}

import { pathToRegexp } from 'path-to-regexp'
const matchedRouteOrAlias = (item: ChildPage): boolean => {
  if ($route.path === item.href) return true
  if (item.alias) {
    const current = $route.path + $route.hash

    if (Array.isArray(item.alias)) {
      return item.alias.some(alias => pathToRegexp(alias).test(current))
    } else {
      return pathToRegexp(item.alias).test(current)
    }
  }
  return false
}

const beforeEnter = (el: Element): void => {
  ;(el as HTMLElement).style.height = '0'
}
const enter = (el: Element): void => {
  const height = (el as HTMLElement).scrollHeight
  ;(el as HTMLElement).style.height = `${height}px`
}
const beforeLeave = (el: Element): void => {
  ;(el as HTMLElement).style.height = `${(el as HTMLElement).scrollHeight}px`
}
const leave = (el: Element): void => {
  ;(el as HTMLElement).style.height = '0'
}
</script>

<style lang="scss" scoped>
.side-menu-item {
  .title-item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    padding: 18px;

    .text {
      display: flex;
      align-items: center;
      gap: 18px;
    }

    &:hover {
      background: $light-blue;
    }
  }

  .child-items {
    .child-item {
      background: rgba(225, 229, 235, 0.6);
      font-size: $font-size-12;
      margin-bottom: 1px;
      mix-blend-mode: multiply;
      height: 39.8px;

      &.-matchedRoute {
        background: transparent linear-gradient(90deg, #0f86ff, #29a4ff) 70% 70%
          no-repeat padding-box;
        color: $white-color;

        .child-link {
          &:hover {
            background: none;
          }

          &::before {
            border: 1px solid $white-color;
            border-radius: 2px;
            box-sizing: border-box;
            content: '';
            height: calc(100% - 14px);
            position: absolute;
            left: 0;
            top: 7px;
          }
        }
      }

      .child-link {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: $font-weight-700;
        cursor: pointer;
        height: 100%;
        text-decoration: none;
        padding: 13px 0 13px 56px;

        &:hover {
          background: $light-blue;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  &.-isCollapsed {
    height: 66.5px;

    .title-item {
      padding: 0;
      justify-content: center;

      .text {
        flex-direction: column;

        .title {
          font-size: $font-size-10;
          text-align: center;
          margin-top: -10px;
          transform: scale(0.9);
        }
      }
    }
  }

  .arrow-icon {
    font-weight: $font-weight-bold;
    text-align: center;
    transition: transform 0.15s ease;
    width: 30px;

    &::after {
      border-right: 2px solid $black-color;
      border-top: 2px solid $black-color;
      content: '';
      height: 8px;
      width: 8px;
      margin-top: -4px;
      position: absolute;
      right: 22px;
      top: 50%;
      transform: rotate(45deg);
    }

    &.-white {
      &::after {
        border-right: 2px solid $white-color;
        border-top: 2px solid $white-color;
      }
    }

    &.-open {
      &::after {
        transform: rotate(135deg);
      }
    }
  }

  .accordion-enter-active,
  .accordion-leave-active {
    transition: height 0.15s ease;
    overflow: hidden;
  }
  .accordion-enter-from,
  .accordion-leave-to {
    height: 0;
    overflow: hidden;
  }
}
</style>
