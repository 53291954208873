<template>
  <div class="app-breadcrumb">
    <div
      v-for="route in routes"
      :key="route.path"
      class="path"
    >
      <label>{{ route.label }}</label>
      <span class="separator">/</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const $route = useRoute()
const { findRoutes } = useRouterUtils()
const routes = computed(() =>
  findRoutes({ result: [], path: $route.path, root: 'company' }),
)
</script>

<style lang="scss" scoped>
.app-breadcrumb {
  font-size: $font-size-small;
  line-height: $lh-50;

  > .path {
    display: inline-block;

    > .separator {
      display: inline-block;
      margin: 0 4px;
    }

    &:last-child {
      > .separator {
        display: none;
      }
    }
  }
}
</style>
