<template>
  <div
    class="app-side-menu"
    :class="{
      '-isCollapsed': isCollapsed,
    }"
  >
    <div class="toggle">
      <div
        class="menu"
        @click="toggleMenu"
      >
        <span class="line"></span>
      </div>
      <div
        v-if="!isCollapsed"
        class="close"
        @click="close"
      ></div>
    </div>
    <div class="logo">
      <app-img
        src="/logo/logo_en.svg"
        alt="logo"
        width="36"
        height="32"
      />
      <app-img
        v-if="!isCollapsed"
        src="/logo/logo-text.png"
        alt="logo-text"
        width="136"
        height="31"
      />
    </div>
    <div class="scrollWrapper">
      <ul :class="{ '-isCollapsed': isCollapsed }">
        <li
          v-for="(menu, index) in menus"
          :key="index"
          :class="{
            '-matchedRoute': isPathMatched(menu),
          }"
        >
          <div
            v-if="!menu.child && canAccessPage(menu)"
            class="link-wrapper"
          >
            <nuxt-link
              :to="menu.href"
              class="link"
              @click="hideChildItem"
            >
              <div class="icon-wrapper">
                <app-icon
                  v-if="isPathMatched(menu)"
                  :name="`${menu.icon}White`"
                  :width="menu.icon === 'sideMenu/notification' ? '18' : '20'"
                  :height="menu.icon === 'sideMenu/notification' ? '25' : '20'"
                />
                <app-icon
                  v-else
                  :name="menu.icon"
                  width="20"
                  height="20"
                />
              </div>
              <div class="title">
                <span>{{ menu.title }}</span>
              </div>
              <app-badge
                v-if="menu.badge && menu.badge.text && menu.badge.text > 0"
                :num="menu.badge.text"
                class="badge"
              />
            </nuxt-link>
          </div>
          <div v-else>
            <div v-if="menu.title === 'メンバー' && canAccessPage(menu)">
              <side-menu-item
                :title="menu.title"
                :icon="menu.icon"
                :childs="menu.child"
                :visible="visibleMember"
                :collapsed="isCollapsed"
                @open="showMember"
              />
            </div>
            <div v-if="menu.title === '設定' && canAccessPage(menu)">
              <side-menu-item
                :title="menu.title"
                :icon="menu.icon"
                :childs="menu.child"
                :visible="visibleSettings"
                :collapsed="isCollapsed"
                @open="showSettings"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { pagesInfo } from '~/config/pagesInfo'
import type { PagesInfo, PageInfo, ChildPage } from '~/config/pagesInfo'
import type { RoleKey } from '~/types/roleKey'

withDefaults(defineProps<{ isCollapsed: boolean }>(), {
  isCollapsed: false,
})
const emit = defineEmits([
  'toggleMenu',
  'closeMenu',
  'showMember',
  'showSettings',
])

const toggleMenu = (): void => {
  emit('toggleMenu')
}

const close = (): void => {
  emit('closeMenu')
}

const $store = useStore()
const $route = useRoute()
const { canCsvAdd } = useAuth()

const menus = computed<PagesInfo>(() => {
  return pagesInfo.map((page: PageInfo) => {
    if (page.title === 'メッセージ') {
      return {
        ...page,
        badge: {
          text: $store.getMessageUnread,
        },
      }
    }
    if (page.child) {
      return {
        ...page,
        child: page.child.map((childPage: ChildPage) => {
          // 入社1年後活躍アンケート
          if (childPage.href === '/company/member/anniversary') {
            return {
              ...childPage,
              badge: {
                text: $store.getNewAnniversaryAnswer,
              },
            }
          }

          // メンバー一括追加／更新
          // 追加ができない権限なので「追加」をタイトルから削除する
          if (childPage.href === '/company/member/csv' && !canCsvAdd) {
            return {
              ...childPage,
              title: 'メンバー一括更新',
            }
          }
          return childPage
        }),
      }
    }
    return page
  })
})

/* eslint-disable   @typescript-eslint/no-explicit-any */
const isPathMatched = (menu: any): boolean => {
  const currentPath = $route.path
  if (currentPath === menu.href) {
    return true
  }
  if (menu.alias) {
    return menu.alias.some((aliasPath: string) =>
      currentPath.includes(aliasPath.split('/:')[0]),
    )
  }
  return false
}

const visibleMember = ref(false)
const visibleSettings = ref(false)
const showMember = (): void => {
  visibleMember.value = !visibleMember.value
  visibleSettings.value = false
  emit('showMember')
}
const showSettings = (): void => {
  visibleSettings.value = !visibleSettings.value
  visibleMember.value = false
  emit('showSettings')
}
const hideChildItem = (): void => {
  visibleMember.value = false
  visibleSettings.value = false
}

// ルートメタ情報を監視して表示状態を更新
watchEffect(() => {
  const layoutProps = $route.meta.layoutProps
  visibleMember.value = layoutProps === 'member'
  visibleSettings.value = layoutProps === 'settings'
})

const canAccessPage = (page: PageInfo): boolean => {
  const userRole = $store.getUser?.roles ? $store.getUser?.roles[0].value : null
  if (!userRole) {
    return false
  }

  if (page.roles?.includes(userRole as RoleKey)) {
    return true
  }

  if (page.child) {
    return page.child.some((subPage: ChildPage) =>
      subPage.roles?.includes(userRole as RoleKey),
    )
  }
  return false
}
</script>

<style lang="scss" scoped>
.app-side-menu {
  display: grid;
  grid-template-rows: 64px 32px 1fr;
  max-width: 259px;
  width: 259px;
  height: 100vh;
  box-sizing: border-box;
  background: $gray-11-transparent;
  backdrop-filter: blur(20px);
  border: 1px solid $white-color;
  border-radius: 10px;
  box-shadow: $box-shadow-8;
  transition: max-width 0.2s ease;
  overflow: hidden;

  &.-isCollapsed {
    max-width: 55px;
  }

  .toggle {
    position: relative;
    display: flex;

    .menu {
      position: relative;
      width: 46px;
      min-width: 46px;
      height: 46px;
      margin-top: 7px;
      margin-bottom: 11px;
      margin-left: 4px;
      cursor: pointer;
      background-color: $gray-11;
      border-radius: 23px;

      .line {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 20px;
        height: 2px;
        margin: auto;
        background-color: $black-color;
        border-radius: 1px;

        &::before,
        &::after {
          position: absolute;
          display: block;
          height: 100%;
          content: '';
          background-color: $black-color;
          border-radius: 1px;
        }

        &::before {
          top: -6px;
          width: 100%;
        }

        &::after {
          top: 6px;
          width: 50%;
        }
      }
    }

    .close {
      position: absolute;
      top: 50%;
      right: 7px;
      width: 46px;
      height: 46px;
      box-sizing: border-box;
      cursor: pointer;
      background-color: rgba($white-color, 0.5);
      border: 1px solid $white-color;
      border-radius: 23px;
      transform: translateY(-50%);

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 80001;
        width: 7px;
        height: 7px;
        content: '';
        border-top: 2px solid $dark-blue-color;
        border-left: 2px solid $dark-blue-color;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .logo {
    display: flex;
    gap: 11px;
    padding-left: 11px;
  }

  .scrollWrapper {
    @include scrollbar-style;

    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 106px);
    padding-top: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: $font-weight-bold;
      font-size: $font-size-14;

      &.-matchedRoute {
        background: transparent linear-gradient(90deg, #0f86ff, #29a4ff) 70% 70%
          no-repeat padding-box;
        color: $white-color;

        .link-wrapper {
          &:hover {
            background: none;
          }

          .link {
            &::before {
              border: 1px solid $white-color;
              border-radius: 2px;
              box-sizing: border-box;
              content: '';
              height: 41px;
              position: absolute;
              left: 0;
            }
          }
        }
      }

      .link-wrapper {
        width: 100%;
        height: 55px;

        &:hover {
          background: $light-blue;
        }
      }

      .link {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 18px;
        height: 100%;
        padding: 17px 18px;
        .badge {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    &.-isCollapsed {
      display: grid;
      grid-template-rows: repeat(7, 66.5px);

      li {
        width: 54px;
        word-break: auto-phrase;

        .link {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0;
          padding: 0;

          .icon-wrapper {
            display: flex;
            justify-content: center;
            padding: 17px 0 18px;
          }

          .title {
            font-size: $font-size-10;
            text-align: center;
            margin-top: -10px;
            transform: scale(0.9);
          }
        }
      }
    }
  }
}
</style>
