<template>
  <div
    class="company-page"
    :class="{ '-collapsed': !isCollapsed }"
  >
    <div class="grid-area-side-menu">
      <app-side-menu
        :is-collapsed="isCollapsed"
        @toggle-menu="toggleMenu"
        @close-menu="closeMenu"
        @show-member="showMember"
        @show-settings="showSettings"
      />
    </div>
    <div class="grid-area-header">
      <app-header
        :is-collapsed="isCollapsed"
        :user="user"
      />
    </div>
    <div class="grid-area-container">
      <div class="content">
        <div class="page">
          <nuxt-page />
        </div>
        <app-footer class="footer" />
        <force-logout-dialog
          v-if="logoutReason"
          :reason="logoutReason"
        />
      </div>
    </div>
    <div id="teleportationAppDialog" />
  </div>
</template>

<script setup lang="ts">
// TODO: dialog用のportalを実装する
const { $client } = useNuxtApp()
onMounted(() => {
  window.onstorage = async (event: StorageEvent): Promise<void> => {
    const storageName = 'departments'
    if (event.key === storageName) {
      const res = await $client.v4.common.departments.search.$get()
      $store.setDepartments(res.result.departments)
      localStorage.removeItem(storageName)
    }
  }
})

const $route = useRoute()
watch(
  () => $route.path,
  (to, from) => {
    const disableScrollToTopPages = [
      'company-member-index',
      'company-member-detail-code',
      'company-member-form-code',
      'company-message-index',
      'company-message-detail-code',
      'company-message-form-code',
      'company-setting-account-department-detail-id',
      'company-setting-account-department-form-id',
    ]
    const isDisableScrollToTopPage =
      to !== from &&
      $route.name &&
      disableScrollToTopPages.includes($route.name.toString())
    definePageMeta({
      scrollToTop: !!isDisableScrollToTopPage,
    })
  },
  { immediate: true },
)

const $store = useStore()
const user = $store.getUser

const isCollapsed = ref(false)

const toggleMenu = (): void => {
  isCollapsed.value = !isCollapsed.value
}

const closeMenu = (): void => {
  isCollapsed.value = true
}
const showMember = (): void => {
  isCollapsed.value = false
}
const showSettings = (): void => {
  isCollapsed.value = false
}

const logoutReason = computed(() => {
  return !$store.getUser?.current_ip_is_valid
    ? 'currentIpIsInvalid'
    : $store.getUser.new_password_needed
      ? 'newPasswordNeeded'
      : ''
})
</script>

<style lang="scss" scoped>
$sideMenuWidth: 259px;
$footerHeight: 41px;

.company-page {
  display: grid;
  grid-template:
    'Header Header'
    'SideMenu Container';
  grid-template-columns: 54px 1fr;

  &.-collapsed {
    grid-template-columns: 259px 1fr;
  }

  box-sizing: border-box;
  background-image: url('~/assets/img/pages/company/bg/bg_top.png'),
    url('~/assets/img/pages/company/bg/bg_bottom.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position:
    left top,
    left bottom;
  background-size: contain;
  overflow: hidden;

  .grid-area-side-menu {
    grid-area: SideMenu;
    z-index: $zindex-sidemenu;
  }

  .grid-area-header {
    grid-area: Header;
    position: fixed;
    width: 100%;
    z-index: $zindex-header;
  }

  .grid-area-container {
    @include scrollbar-style;

    grid-area: Container;
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;

    .content {
      min-width: calc(1366px - $sideMenuWidth);

      .page {
        padding: 83px 20px 50px;
        min-height: calc(100vh - $footerHeight);
      }
    }
  }
}
</style>
